import {format, distanceInWords, differenceInDays} from 'date-fns'
import ja from 'date-fns/locale/ja'

import React, {useEffect, useState, useRef} from 'react'
import axios from 'axios'
import styled from 'styled-components'

import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'

import HeadingSpan from './heading-span'
import Button from './button'
import {colors, font, fontfamily} from '../styles/variables'

const Wrapper = styled.article`
  display: block;
  padding: 4rem 0;
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Heading = styled.div`
  max-width: 740px;
  margin: 0 auto;
`

const PageTitle = styled.h1`
  font-size: 2.0rem;
  font-weight: 700;
  font-family: ${fontfamily.jaRounded};
  display: block;
  margin: 0.8rem 2.4rem 3.6rem 2.4rem;
  /* border-bottom: 1px solid ${colors.gray}; */
  text-align: center;
  display: block;
  padding-bottom: 2.4rem;
`

const Title = styled.h2`
  font-size: 2.0rem;
  font-weight: 700;
  font-family: ${fontfamily.jaRounded};
  display: block;
  /* border-bottom: 1px solid ${colors.gray}; */
  padding-bottom: 2.4rem;
  max-width: 680px;
  margin: 0rem auto 3.6rem auto;
  padding: 3.6rem 0 0 0;
`

const Aside = styled.aside`
  display: block;
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

const DateCats = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  margin: 0 2.4rem;
`

const DateTime = styled.span`
  display: block;
  color: ${colors.lightNavy};
  margin: 0 0.8rem 0 0;
`

const Cats = styled.ul`
  display: flex;
  flex-direction: row;
`
const Cat = styled.li`
  color: ${colors.green};
  margin: 0 0.8rem 0 0;
`

const Loading = styled.span`
  text-align: center;
  padding: 8rem;
  display: block;
  margin: auto;
  font-size: 2rem;
`

const Faq = styled.div`
  display: block;
  max-width: 680px;
  margin: 2.4rem auto 2rem auto;
  padding: 0 0 1.2rem 0;
  border-bottom: 1px solid ${colors.gray};
  h2,h3,h4,h5{
    margin-top: 0;
    padding-top: 0;
  }
  @media (min-width: 1024px) {
    margin: 4.8rem auto 4rem auto;
  }

`

const Question = styled.h4`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${colors.navy};
  font-family: ${fontfamily.jaRounded};
  display: block;
  max-width: 680px;
  margin: 0.8rem auto 1.6rem auto;
  position: relative;
  padding: 0 0 0 24px;
  cursor: pointer;
  &:before{
    content: '';
    position: absolute;
    height: 2px;
    width: 1.8rem;
    top: 10px;
    left: 0;
    background: ${colors.blue};
    border-radius: 4px;
    transition: all 250ms ease-in-out;
  }
  &.on:before{
    transform: rotate(180deg);
  }
  &:after{
    content: '';
    position: absolute;
    height: 2px;
    width: 1.8rem;
    top: 10px;
    left: 0;
    background: ${colors.blue};
    border-radius: 4px;
    transform: rotate(90deg);
    transition: all 250ms ease-in-out;
  }
  &.on:after{
    transform: rotate(180deg);
  }
`

const Answer = styled.div`
  height: 0;
  /* max-height: 0; */
  transition: all 800ms;
  overflow-y: hidden;
  &.on{
    height: auto;
    /* max-height: 1000px; */
    overflow-y: hidden;
    height: auto;
    transform-origin: top left;
  }
`

function FaqSingle (props) {
  const {_rawBody, title, mainImage, publishedAt, slug} = props
  const [faqs, setFaqs] = useState([])

  const ref = useRef({isMount: false}).current

  function toggleAnswer (el) {
    const question = document.getElementById(`_question-${el}`)
    const answer = document.getElementById(`_answer-${el}`)

    question.classList.toggle('on')
    answer.classList.toggle('on')
  }
  useEffect(() => {
    const slugIds = {
      'general': '3ed9e63a-8e66-4293-85e4-c83bbfbe14c9',
      'summer-winter-schools': '9ce3fced-4215-468c-ad46-ccdef2e63a73',
      'eiken': 'bf8a1c99-58f9-43e8-befa-fac8a282f813'
    }

    const pageId = slugIds[slug.current]

    if (!ref.isMount) {
      axios({
        method: 'post',
        url: 'https://42w07he8.api.sanity.io/v1/graphql/production/default',
        data: {
          query: `query{\n  Faq( id: "${pageId}" ){\n    faqContent{\n      ... on FaqTitle{\n        _key\n      \t_type\n\t\t\t\tsectionTitle        \n      }\n      ... on FaqItem {\n        _key\n        _type\n        question\n\t\t\t\tanswerRaw\n      }\n    }\n  }\n}`
        }
      }).then(response => {
        setFaqs(response.data.data.Faq.faqContent)
        ref.isMount = true
      })
    }
  })

  return (
    <Wrapper>
      <HeadingSpan title='よくある質問' titleEn='FAQ' />
      <Heading>
        <PageTitle>
          {title}
        </PageTitle>
      </Heading>
      {
        faqs.length === 0 && (
          <Loading>読み込み中</Loading>
        )
      }
      {
        faqs.length > 1 && (
          <Text>
            {faqs.map(faq => {
              if (faq._type === 'faqTitle') {
                const title = faq && faq.sectionTitle
                return (
                  title && <Title key={faq._key}>{title}</Title>
                )
              }
              if (faq._type === 'faqItem') {
                const question = faq && faq.question
                const answer = faq && faq.answerRaw
                const id = faq && `${faq._key}`
                const qestionId = faq && `_question-${faq._key}`
                const answerId = faq && `_answer-${faq._key}`
                return (
                  <Faq key={faq._key}>
                    <Question id={qestionId} onClick={() => toggleAnswer(id)}>{question}</Question>
                    <Answer id={answerId}>
                      <PortableText blocks={answer} />
                    </Answer>
                  </Faq>
                )
              }
            })}
          </Text>
        )}

      <Button link='/faq/' label='FAQ一覧へ' />
    </Wrapper>
  )
}

export default FaqSingle
